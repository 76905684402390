
module.exports = (function(){
  var d = _eai_d;
  var r = _eai_r;
  window.emberAutoImportDynamic = function(specifier) {
    if (arguments.length === 1) {
      return r('_eai_dyn_' + specifier);
    } else {
      return r('_eai_dynt_' + specifier)(Array.prototype.slice.call(arguments, 1))
    }
  };
  window.emberAutoImportSync = function(specifier) {
    return r('_eai_sync_' + specifier)(Array.prototype.slice.call(arguments, 1))
  };
  function esc(m) {
    return m && m.__esModule ? m : Object.assign({ default: m }, m);
  }
    d('@amplitude/analytics-browser', EAI_DISCOVERED_EXTERNALS('@amplitude/analytics-browser'), function() { return esc(require('@amplitude/analytics-browser')); });
    d('fast-deep-equal', EAI_DISCOVERED_EXTERNALS('fast-deep-equal'), function() { return esc(require('fast-deep-equal')); });
    d('fast-memoize', EAI_DISCOVERED_EXTERNALS('fast-memoize'), function() { return esc(require('fast-memoize')); });
    d('intersection-observer-admin', EAI_DISCOVERED_EXTERNALS('intersection-observer-admin'), function() { return esc(require('intersection-observer-admin')); });
    d('intl-messageformat', EAI_DISCOVERED_EXTERNALS('intl-messageformat'), function() { return esc(require('intl-messageformat')); });
    d('intl-messageformat-parser', EAI_DISCOVERED_EXTERNALS('intl-messageformat-parser'), function() { return esc(require('intl-messageformat-parser')); });
    d('raf-pool', EAI_DISCOVERED_EXTERNALS('raf-pool'), function() { return esc(require('raf-pool')); });
    d('remote-promises', EAI_DISCOVERED_EXTERNALS('remote-promises'), function() { return esc(require('remote-promises')); });
    d('swiper', EAI_DISCOVERED_EXTERNALS('swiper'), function() { return esc(require('swiper')); });
    d('tooltip.js', EAI_DISCOVERED_EXTERNALS('tooltip.js'), function() { return esc(require('tooltip.js')); });
})();
